<template>
    <div style="z-index:2000; position:absolute">
        <TeacherNeedReimbursed v-if="displayTeacherModal" :title="'Vérifier les modalités de défraiements'"
            :message="'Voulez-vous vérifier les modalités de défraiements de ce formateur ?'"
            :primaryButtonLabel="'Enregistrer'" :secondaryButtonLabel="'Annuler'" :teacher="selectedPerson"
            :type="typeTeacher" @confirm="saveTeachers" @close="displayTeacherModal = false" :key="reimburseKey"
            :trainingId="route.params.idTraining" />
    </div>
    <AppLayout>
        <header>
            <div class="mx-auto max-w-10xl px-4 sm:px-6 lg:px-8 mb-12 flex ">
                <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900"><span
                        v-if="!route.params.idTraining">Créer</span><span v-else>Modifier</span> une formation</h1>
                <div class="ml-auto" v-if="route.params.idTraining"><router-link
                        :to="'/formations/' + route.params.idTraining"><button class="ml-auto btn-secondary">Revenir à
                            la
                            fiche formation</button></router-link></div>
            </div>
        </header>
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="space-y-10 divide-y divide-gray-900/10">
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                    <div class="px-4 sm:px-0">
                        <h2 class="text-base font-semibold leading-7 text-gray-900">Informations générales</h2>
                        <p class="mt-1 text-sm leading-6 text-gray-600">Informations générales de la formation.</p>
                    </div>

                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div class="col-span-full">
                                    <label for="title"
                                        class="block text-sm font-medium leading-6 text-gray-900">Intitulé</label>
                                    <div class="mt-2">
                                        <div
                                            class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input type="text" name="title" id="title" v-model="formTraining.title"
                                                class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:col-span-full">
                                    <label for="comment"
                                        class="block text-sm font-medium leading-6 text-gray-900">Informations
                                        complémentaires</label>
                                    <div class="mt-2">
                                        <textarea rows="4" name="infos" id="infos" v-model="formTraining.complement"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="Descriptif succint du contenu de la formation" />
                                    </div>
                                </div>
                                <div class="sm:col-span-full" style="z-index:10; position:relative">
                                    <SelectComponent v-model="formTraining.category"
                                        :arrayData="relevantTrainingCategories" :label="'Catégorie'"
                                        :placeholder="'Choisissez une catégorie'" :key="componentKey" />

                                </div>
                                <div class="sm:col-span-full" style="z-index:9; position:relative">
                                    <Listbox as="div" v-model="formTraining.theme" v-if="relevantTrainingThemes.length">
                                        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Thème
                                        </ListboxLabel>
                                        <div class="relative mt-2">
                                            <ListboxButton
                                                class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                <span class="block truncate" v-if="formTraining.theme">{{
                                                    formTraining.theme.numerotation }}. {{
                                                        formTraining.theme.name }}</span>
                                                <span v-else class="text-gray-500">Choisissez un thème</span>
                                                <span
                                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                        aria-hidden="true" />
                                                </span>
                                            </ListboxButton>

                                            <transition leave-active-class="transition ease-in duration-100"
                                                leave-from-class="opacity-100" leave-to-class="opacity-0">
                                                <ListboxOptions
                                                    class="absolute z-1000 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    <ListboxOption as="template"
                                                        v-for="itemList in relevantTrainingThemes" :key="itemList.id"
                                                        :value="itemList" v-slot="{ active, selected }">
                                                        <li
                                                            :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                            <span
                                                                :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                                                    itemList.numerotation }}. {{
                                                                    itemList.name }}</span>
                                                            <span v-if="selected"
                                                                :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        </li>
                                                    </ListboxOption>
                                                </ListboxOptions>
                                            </transition>
                                        </div>
                                    </Listbox>
                                </div>
                                <div class="sm:col-span-3" style="z-index:8; position:relative">
                                    <SelectComponent v-model="formTraining.type" :arrayData="relevantTrainingTypes"
                                        :label="'Type'" :placeholder="'Choisissez un type'" :key="componentKey" />
                                </div>

                                <div class="sm:col-span-3" style="z-index:7; position:relative">
                                    <SelectComponent v-model="formTraining.unions" :arrayData="relevantUnions"
                                        :label="'Syndicat'" :placeholder="'Choisissez un syndicat'"
                                        :key="componentKey" />
                                </div>
                                <div class="sm:col-span-3">
                                    <label for="max_attendees"
                                        class="block text-sm font-medium leading-6 text-gray-900">Nombre de
                                        places</label>
                                    <div class="mt-2">
                                        <div
                                            class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input type="number" name="max_attendees" id="max_attendees"
                                                v-model="formTraining.max_attendees"
                                                class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>
                                </div>
                                <div class="sm:col-span-3">
                                    <div>
                                        <SwitchComponent v-model="formTraining.reserved" :trueValue="'Réservée'"
                                            :falseValue="'Non réservée'" :key="componentKey" />
                                    </div>
                                    <div class="sm:col-span-3 mt-3">
                                        <div>
                                            <SwitchComponent v-model="formTraining.is_visio"
                                                :trueValue="'Formation en visio'"
                                                :falseValue="'Formation en présentiel'" :key="componentKey" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-5">
                                <div class="sm:col-span-3">
                                    <label for="start-on"
                                        class="block text-sm font-medium leading-6 text-gray-900">Début
                                    </label>
                                    <div class="mt-2">
                                        <input type="datetime-local" name="start-on" id="start-on"
                                            v-model="formTraining.start_date" autocomplete="given-name"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <label for="end-on"
                                        class="block text-sm font-medium leading-6 text-gray-900">Fin</label>
                                    <div class="mt-2">
                                        <input type="datetime-local" name="end-on" id="end-on"
                                            v-model="formTraining.end_date" autocomplete="family-name"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</button>
                            <button class="btn-primary" @click.prevent="saveTraining"
                                v-if="modification || route.params.idTraining">Enregistrer</button>
                            <button type="submit" @click.prevent="createTraining" v-else class="btn-primary"
                                :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                        </div>
                    </form>
                </div>

                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                    <div class="px-4 sm:px-0">
                        <h2 class="text-base font-semibold leading-7 text-gray-900">Localisation</h2>
                        <p class="mt-1 text-sm leading-6 text-gray-600">Localisation de la formation.</p>
                    </div>

                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div class="sm:col-span-full" style="z-index:10; position:relative">
                                    <SelectComponent v-model="formTraining.place" :arrayData="relevantPlaces"
                                        :label="'Localisation'" :placeholder="'Choisissez un lieu de stage'"
                                        :key="componentKey" />
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</button>
                            <button class="btn-primary" @click.prevent="saveTraining"
                                v-if="modification">Enregistrer</button>
                            <button type="submit" @click.prevent="createTraining" v-else class="btn-primary"
                                :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                        </div>
                    </form>
                </div>

                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
                    v-if="profile.role?.slug !== 'responsable-pedagogique'">
                    <div class="px-4 sm:px-0">
                        <h2 class="text-base font-semibold leading-7 text-gray-900">Frais</h2>
                        <p class="mt-1 text-sm leading-6 text-gray-600">Restauration du soir, transport et hébergement.
                        </p>
                    </div>

                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 items-center">

                                <div class="sm:col-span-3">
                                    <SwitchComponent v-model="formTraining.transportation_fees" :trueValue="'Transport'"
                                        :falseValue="'Pas de transport'" :key="componentKey" />
                                </div>

                                <div class="sm:col-span-3" v-if="formTraining.transportation_fees == true">
                                    <label for="trips" class="block text-sm font-medium leading-6 text-gray-900">Nombre
                                        d'allers-retours</label>
                                    <div class="mt-2">
                                        <input type="number" name="trips" id="trips"
                                            v-model="formTraining.number_of_round_trips"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>

                                <div class="sm:col-span-3">
                                    <SwitchComponent v-model="formTraining.accommodation" :trueValue="'Hébergement'"
                                        :falseValue="'Pas d\'hébergement'" :key="componentKey" />
                                </div>
                                <div class="sm:col-span-3">
                                    <label for="trips" class="block text-sm font-medium leading-6 text-gray-900">Nombre
                                        de repas du soir</label>
                                    <div class="mt-2">
                                        <input type="number" name="trips" id="trips" v-model="formTraining.dinners"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</button>
                            <button class="btn-primary" @click.prevent="saveTraining"
                                v-if="modification">Enregistrer</button>
                            <button type="submit" @click.prevent="createTraining" v-else class="btn-primary"
                                :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                        </div>
                    </form>
                </div>

                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
                    v-if="profile.role?.slug !== 'responsable-pedagogique'">
                    <div class="px-4 sm:px-0">
                        <h2 class="text-base font-semibold leading-7 text-gray-900">Tarifs</h2>
                        <p class="mt-1 text-sm leading-6 text-gray-600">Coût de la formation.</p>
                    </div>

                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div class="sm:col-span-3">
                                    <SwitchComponent v-model="formTraining.paid_training"
                                        :trueValue="'La formation est payante'"
                                        :falseValue="'La formation n\'est pas payante'" :key="componentKey" />
                                </div>

                                <div class="sm:col-span-3" v-if="formTraining.paid_training">
                                    <label for="price"
                                        class="block text-sm font-medium leading-6 text-gray-900">Prix</label>
                                    <div class="relative mt-2 rounded-md shadow-sm">
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                            <span class="text-gray-500 sm:text-sm">€</span>
                                        </div>
                                        <input type="number" name="price" id="price" v-model="formTraining.amount"
                                            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            placeholder="0.00" aria-describedby="price-currency" />
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</button>
                            <button class="btn-primary" @click.prevent="saveTraining"
                                v-if="modification">Enregistrer</button>
                            <button type="submit" @click.prevent="createTraining" v-else class="btn-primary"
                                :class="[!isFormValid ? 'opacity-30' : '']" :disabled="!isFormValid">Créer</button>
                        </div>
                    </form>
                </div>

                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3"
                    :class="!route.params.idTraining ? 'opacity-50 ' : ''">
                    <div class="px-4 sm:px-0">
                        <h2 class="text-base font-semibold leading-7 text-gray-900">Équipe pédagogique</h2>
                        <p class="mt-1 text-sm leading-6 text-gray-600">Constituez l'ensemble de l'équipe pédagogique.
                        </p>
                    </div>

                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div class="col-span-full">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">Responsable pédagogique
                                    </h2>
                                </div>
                                <div class="col-span-full">
                                    <TeachersRelatedList :teachers="formTraining.pedagogical_supervisors"
                                        :type="'responsable-pedagogique'" :idTraining="route.params.idTraining"
                                        :key="componentKey" @confirm="loadTraining" />
                                    <div class="flex items-center space-x-3 mb-10 mt-5">
                                        <button type="button" @click.prevent="addTeamMember('responsable-pedagogique')"
                                            class="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                            <PlusIcon class="h-5 w-5" aria-hidden="true" />
                                        </button>
                                        <label for="searchSpeaker"
                                            class="text-sm font-medium leading-6 text-gray-900">Ajoutez des responsables
                                            pédagogiques
                                        </label>
                                    </div>
                                </div>
                                <div class="sm:col-span-full"
                                    :class="!route.params.idTraining ? 'pointer-events-none' : ''">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">Intervenants</h2>
                                    <TeachersRelatedList :teachers="formTraining.teachers" :key="componentKey"
                                        :type="'intervenant'" :idTraining="route.params.idTraining"
                                        @confirm="loadTraining" />
                                    <div class="flex items-center space-x-3 mt-5">
                                        <button type="button" @click.prevent="addTeamMember('intervenant')"
                                            class="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                            <PlusIcon class="h-5 w-5" aria-hidden="true" />
                                        </button>
                                        <label for="searchSpeaker"
                                            class="text-sm font-medium leading-6 text-gray-900">Ajoutez des intervenants
                                        </label>
                                    </div>
                                </div>
                                <TrainingSpeakers v-if="speakersModalOpen" @close="speakersModalOpen = false"
                                    @confirm="openModalReimbursment" :idTraining="route.params.idTraining"
                                    :type="typeTeacher" />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="w-full pt-5 flex pb-10" v-if="route.params.idTraining">
                    <button class="ml-auto rounded-md bg-emerald-400 px-3 py-2 text-md font-medium text-white shadow-sm
                                hover:bg-emerald-300 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        @click.prevent="saveTraining('end')">
                        Terminer l'édition
                    </button>
                </div>
            </div>
        </div>
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false" />
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import AppLayout from '@/layouts/AppLayout.vue';
import { PhotoIcon } from '@heroicons/vue/24/solid';
import { ref, onMounted, computed } from 'vue';
import {
    Switch, SwitchGroup, SwitchLabel, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { PlusIcon } from '@heroicons/vue/20/solid'
import TrainingSpeakers from '@/components/trainings/TrainingSpeakers.vue';
import client from '@/api/client';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import SelectComponent from '@/components/form/SelectComponent.vue';
import SwitchComponent from '@/components/form/SwitchComponent.vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import TeachersRelatedList from '@/components/teachers/TeachersRelatedList.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import AddSupervisors from '@/components/supervisors/AddSupervisor.vue';
import moment from 'moment';
import 'moment/locale/fr';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import TeacherNeedReimbursed from '@/components/teachers/TeacherNeedsReimbursed.vue';

moment.locale('fr');

const route = useRoute();
const router = useRouter();
const teacher = [
    { id: 1, name: 'Wade Cooper', online: true },
    { id: 2, name: 'Arlene Mccoy', online: false },
    { id: 3, name: 'Devon Webb', online: false },
    { id: 4, name: 'Tom Cook', online: true },
    { id: 5, name: 'Tanya Fox', online: false },
    { id: 6, name: 'Hellen Schmidt', online: true },
    { id: 7, name: 'Caroline Schultz', online: true },
    { id: 8, name: 'Mason Heaney', online: false },
    { id: 9, name: 'Claudie Smitham', online: true },
    { id: 10, name: 'Emil Schaefer', online: false },
];
const formTraining = ref({
    title: '',
    category: '',
    type: '',
    theme: '',
    public: '',
    reserved: false,
    startOn: '',
    endOn: '',
    speakers: [],
    price: 0,
    dinners: 0,
    accommodation: false,
    transportation_fees: false,
    paid_training: false,
    complement: '',
    unions: '',
    place: '',
    max_attendees: 0,
    number_of_round_trips: 0,
    status: {
        id: 1,
        name: "À venir",
    }
});
const selected = ref(teacher[3]);
const relevantTrainingThemes = ref([]);
const relevantTrainingTypes = ref([]);
const relevantTrainingCategories = ref([]);
const relevantUnions = ref([]);
const relevantStatuses = ref([]);
const relevantPlaces = ref([]);
const componentKey = ref(0);
const modification = ref(false);
const selecteadTeachers = ref([]);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('')
const speakersModalOpen = ref(false);
const datesAreEditable = ref(false);
const displayTeacherModal = ref(false);
const selectedPerson = ref(null);
const reimburseKey = ref(0);
const typeTeacher = ref('');
const profile = ref([]);

const toggleSpeakersModal = () => {
    speakersModalOpen.value = !speakersModalOpen.value;
};
const gotoTrainings = () => {
    router.push({ name: 'Liste des formations' });
};

const mandatoryFields = ['title', 'category', 'type', 'theme', 'start_date', 'end_date', 'max_attendees'];
const isFormValid = computed(() => {
    for (const field of mandatoryFields) {
        if (!formTraining.value[field]) {
            return false;
        } else if (field === 'max_attendees' && formTraining.value[field] < 1) {
            return false;
        }
    }
    return true;
});

const createTraining = async () => {
    const arrayUnions = formTraining.value.unions?.id;
    try {
        const response = await client.post('api/trainings', {
            title: formTraining.value.title,
            training_category_id: formTraining.value.category?.id,
            training_type_id: formTraining.value.type?.id,
            training_theme_id: formTraining.value.theme?.id,
            reserved: formTraining.value.reserved,
            max_attendees: formTraining.value.max_attendees,
            start_date: formTraining.value.start_date,
            end_date: formTraining.value.end_date,
            training_status_id: 1,
            number_of_round_trips: formTraining.value.number_of_round_trips,
            dinners: formTraining.value.dinners,
            accommodation: formTraining.value.accommodation,
            transportation_fees: formTraining.value.transportation_fees,
            paid_training: formTraining.value.paid_training,
            complement: formTraining.value.complement,
            unions: arrayUnions,
            training_place_id: formTraining.value.place?.id,
            amount: formTraining.value.amount,
            is_visio: formTraining.value.is_visio
        })
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        modification.value = true;
        router.push({ name: 'Modifier une formation', params: { 'idTraining': response.data.id } });
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const saveTraining = async (type) => {
    let union_ids = formTraining.value.unions?.id;
    if (formTraining.value.unions) {
        union_ids = [formTraining.value.unions?.id];
    }
    try {
        const response = await client.put(`api/trainings/${route.params.idTraining}`, {
            title: formTraining.value.title,
            training_category_id: formTraining.value.category?.id,
            training_type_id: formTraining.value.type?.id,
            training_theme_id: formTraining.value.theme?.id,
            reserved: formTraining.value.reserved,
            max_attendees: formTraining.value.max_attendees,
            start_date: formTraining.value.start_date,
            end_date: formTraining.value.end_date,
            training_status_id: formTraining.value.status?.id,
            number_of_round_trips: formTraining.value.number_of_round_trips,
            dinners: formTraining.value.dinners,
            accommodation: formTraining.value.accommodation,
            transportation_fees: formTraining.value.transportation_fees,
            paid_training: formTraining.value.paid_training,
            complement: formTraining.value.complement,
            unions: union_ids,
            training_place_id: formTraining.value.place?.id,
            amount: formTraining.value.amount,
            is_visio: formTraining.value.is_visio
        })
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, l\'item a bien été modifié';
        notificationMessage.value = '';
        if (type === 'end') {
            router.push({ name: 'Détails de la formation', params: { id: route.params.idTraining } });
        }
    } catch (error) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
        if (error.response) {
            notificationMessage.value = error.response.data.message;
        }
    }
};

const forceRerender = () => {
    componentKey.value += 1;
};

const addTeamMember = (type) => {
    speakersModalOpen.value = true;
    typeTeacher.value = type;
    componentKey.value += 1;
};

const saveTeachers = (type) => {
    speakersModalOpen.value = false;
    displayTeacherModal.value = false;
    selectedPerson.value = null;
    fetchTrainingsServices.fetchTraining(route.params.idTraining).then((data) => {
        formTraining.value = data;
        formTraining.value.unions = formTraining.value.unions[0];
        forceRerender();
    });
};

const loadTraining = () => {
    fetchTrainingsServices.fetchTraining(route.params.idTraining).then((data) => {
        formTraining.value = data;
        formTraining.value.unions = formTraining.value.unions[0];
    });
    forceRerender();
};

const openModalReimbursment = (person, type) => {
    typeTeacher.value = type;
    displayTeacherModal.value = true;
    speakersModalOpen.value = false;
    selectedPerson.value = person;
    reimburseKey.value += 1;
};

onMounted(() => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    fetchTrainingsServices.fetchTrainingCategories().then((data) => {
        relevantTrainingCategories.value = data;
    });
    fetchTrainingsServices.fetchTrainingTypes().then((data) => {
        relevantTrainingTypes.value = data;
    });
    fetchTrainingsServices.fetchTrainingThemes().then((data) => {
        relevantTrainingThemes.value = data;
    });
    fetchTrainingsServices.fetchUnions().then((data) => {
        relevantUnions.value = data;
    });
    fetchTrainingsServices.fetchTrainingStatuses().then((data) => {
        relevantStatuses.value = data;
    });
    fetchTrainingsServices.fetchTrainingPlaces().then((data) => {
        relevantPlaces.value = data;
    });
    if (route.params.idTraining) {
        modification.value = true;
        fetchTrainingsServices.fetchTraining(route.params.idTraining).then((data) => {
            formTraining.value = data;
            formTraining.value.unions = formTraining.value.unions[0];

            forceRerender();
            if (formTraining.value.status?.id === 1) {
                datesAreEditable.value = true;
            }
        });
    }
});


</script>
